import React, { useState, useEffect } from 'react';
import { sendRequest } from "../../apis/APIs";
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
import moment from "moment";
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { handleApiResponse } from "../../apis/apiUtils";
import { useSelector, useDispatch } from 'react-redux';
import TopBackButton from '../../common/TopBackButton';
import appRoutes from '../../configs/AppRoutes';


const Info = () => {
    const params = useParams();
    const [pageDetail, setPageDetail] = useState({});
    const [difference, setDifference] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, user } = useSelector((state) => state.common);

    useEffect(() => {
        getPageDetail();
    }, []);

    const getPageDetail = async () => {
        try {
            let reqUrl = `/user/page/` + params.id;
            const res = await sendRequest(reqUrl, "GET");
            if (res.data.type === "success") {
                setPageDetail(res.data.data);
                if (res.data.data.type === 1) {
                    const startDate = moment(res.data.data.anniversary_date);
                    const now = moment();
                    const years = now.diff(startDate, 'years');
                    const months = now.diff(startDate.add(years, 'years'), 'months');
                    setDifference(`${years} years and ${months} months`);
                }
            }
        } catch (error) {
            console.log('Something went wrong');
        }
    };

    const updateMemberStatus = async (pageId, userId, status) => {
        try {
            let data = { page_id: pageId, user_id: userId, status: status };
            let reqUrl = `/user/page/request`;
            dispatch(callCommonAction({ loading: true }));
            const res = await sendRequest(reqUrl, "POST", data);
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                getPageDetail();
            }
        } catch (error) {
            console.log('Something went wrong');
        }
    };

    const pageCover = `${process.env.REACT_APP_ASSET_ENDPOINT}/${pageDetail.cover_picture}`;

    const [copySuccess, setCopySuccess] = useState('');

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopySuccess('Code copied!');
                setTimeout(() => {
                    setCopySuccess('');
                }, 1000); // Hide the message after 1 second
            })
            .catch(err => setCopySuccess('Failed to copy code.'));
    };


    console.log(pageDetail.members_invited,"pageDetail.members_invited")

    return (
        <div>
            <div className="t-4 position-relative">
                <TopBackButton />
                <div className='page-detail-header shadow' style={{ backgroundImage: `url(${pageCover})` }}>
                    <div className='caption mb-4 px-3'>
                        <h1>
                            {pageDetail.type === 1
                                ? (
                                    <>
                                        {pageDetail.created_by?.name}
                                        <span> ∞ </span>
                                        {pageDetail.member_joined && pageDetail.member_joined.length > 0 && pageDetail.member_joined[0]?.created_by?.name
                                            ? pageDetail.member_joined[0].created_by.name
                                            : '-'}
                                    </>
                                )
                                : pageDetail.name}
                        </h1>
                        <div className="d-flex flex-column position-relative copy-code">
                            <input type='text' className='form-control' value={pageDetail.unique_code} readOnly />
                            <button className='btn btn-link position-absolute right-0 top-0' onClick={() => copyToClipboard(pageDetail.unique_code)}>
                                <i className="fa-solid fa-copy"></i>
                            </button>
                            {copySuccess && <p className='m-0 text-white'>{copySuccess}</p>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='px-3'>
                <div className="title-header">
                    <div>
                        <h5 className="mb-0"><i className="fa-solid fa-users"></i> Member List</h5>
                    </div>

                </div>
                <div className='listing'>
                    {pageDetail.members_invited && pageDetail.members_invited.length > 0 ? (
                        pageDetail.members_invited.map((item, index) => (
                            <div className='list-item'>
                                <div className='d-flex align-items-center'>
                                    <div className='avatar flex-shrink-0 me-2'>
                                        <img src={item.created_by.profile_picture ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${item.created_by.profile_picture}` : process.env.PUBLIC_URL + '/assets/images/user-default.jpg'} width={'50px'} />
                                    </div>
                                    <div className='w-100'>
                                        <h2>{item.created_by.name}</h2>
                                        <p className='m-0'><a href="#" className='link'><i className="fa-solid fa-envelope me-1"></i> {item.created_by.email}</a></p>
                                    </div>
                                </div>
                                <div>
                                    <Dropdown className='custom-dropdown'>
                                        <Dropdown.Toggle as="button" className="btn">
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {
                                                user.id === pageDetail.created_by.id && item.status === 5 ?
                                                    <>
                                                        <Dropdown.Item onClick={() => updateMemberStatus(pageDetail.id, item.created_by.id, 1)}><i className="fa-solid fa-check me-1"></i> Accept</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => updateMemberStatus(pageDetail.id, item.created_by.id, 2)}><i className="fa-solid fa-times me-1"></i> Reject</Dropdown.Item>
                                                    </>
                                                    : ''
                                            }
                                            {
                                                user.id !== pageDetail.created_by.id && item.status === 1
                                                    ?
                                                    <Dropdown.Item onClick={() => updateMemberStatus(pageDetail.id, item.created_by.id, 3)}><i className="fa-solid fa-sign-out-alt me-1"></i> Leave</Dropdown.Item>
                                                    : ''
                                            }
                                            {
                                                user.id === pageDetail.created_by.id && item.status === 1 ?
                                                    <>
                                                        <Dropdown.Item onClick={() => updateMemberStatus(pageDetail.id, item.created_by.id, 4)}><i className="fa-solid fa-trash me-1"></i> Remove</Dropdown.Item>
                                                    </>
                                                    : ''
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No Data Available</p>
                    )}
                </div>
            </div>


            <div className='px-3'>
                <div className="title-header">
                    <div>
                        <h5 className="mb-0"><i className="fa-regular fa-calendar-days"></i> Event List</h5>
                    </div>

                </div>
                <div className='listing'>
                    {pageDetail.events && pageDetail.events.length > 0 ? (
                        pageDetail.events.map((item, index) => (
                            <div className='list-item' key={index}>
                                <div className='d-flex align-items-center'>
                                    <div className='avatar flex-shrink-0 me-2'>
                                        <img src={item.favorite_gallery?.file ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${item.favorite_gallery.file}` : process.env.PUBLIC_URL + '/assets/images/user-default.jpg'} width={'50px'} />
                                    </div>
                                    <div className='w-100'>
                                        <h2>{item.name}</h2>
                                        <p className='m-0 limited-text'> {item.description}</p>
                                    </div>
                                </div>
                                <div>
                                    <Dropdown className='custom-dropdown'>
                                        <Dropdown.Toggle as="button" className="btn">
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => navigate(appRoutes.viewEventPageRoute + '/' + item.id)}><i className="fa-solid fa-eye me-1"></i> View</Dropdown.Item>
                                            <Dropdown.Item onClick={() => navigate(appRoutes.updateEventPageRoute + '/' + item.id)}><i className="fa-solid fa-edit me-1"></i> Update</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No Data Available</p>
                    )}
                </div>
            </div>

        </div>
    );
};

export default Info;
