import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendRequest } from "../../apis/APIs";
import { handleApiResponse } from "../../apis/apiUtils";
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { useNavigate, useLocation } from 'react-router-dom';
import { FloatingLabel, Form } from 'react-bootstrap';
import appRoutes from '../../configs/AppRoutes';

const ForgotPassword = () => {
    const [userDetail, setUserDetail] = useState({ email: '' });
    const [errorsInfo, setErrorsInfo] = useState({});
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { loading } = useSelector((state) => state.common);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetail({ ...userDetail, [name]: value });

        if (errorsInfo.email) {
            setErrorsInfo({ ...errorsInfo, email: '' });
        }
    };

    const validateEmail = () => {
        let isValid = true;
        let errors = {};

        if (!userDetail.email || userDetail.email.trim() === '') {
            errors.email = 'Email is required';
            isValid = false;
        }
        setErrorsInfo(errors);
        return isValid;
    };
    const sentForgetPassword = async (e) => {
        e.preventDefault();
        if (validateEmail()) {
            const res = await sendRequest(`/user/password/email`, "POST", userDetail);
            handleApiResponse(res);
            if (res.data.type === "success") {
                setUserDetail({ ...userDetail, email: '' });
                console.log('res', res.data);
            }
        }
    };

  return (
    <div className="otp-container">
            <div className="siteWidth">
                <div className="d-flex flex-column justify-content-center outer bg-light position-relative">
                    <img className="top-right position-absolute" src="/assets/images/top-right-shape.png" alt="Top Right Shape" />
                    <div className="form-container">
                        <div className="text-center mb-4">
                            <h1 className="title mb-4">Enter Email</h1>
                            
                            <p className="mb-5 text-center small text-primary">Please enter it below to verify your identity.</p>
                        </div>

                        <form className="otp-form" noValidate onSubmit={sentForgetPassword}>
                            <div className="mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Email"
                                >
                                    <Form.Control className="input-field form-control" type="email"
                                        placeholder="Enter your Email"
                                        name="email"
                                        value={userDetail.email}
                                        onChange={handleChange} />
                                </FloatingLabel>
                                <div className="error-message">{errorsInfo.email}</div>

                            </div>
                            <div className="text-center mb-4">
                            <button  onSubmit={sentForgetPassword} disabled={loading ? true : false} className="w-100 btn-lg btn-primary shadow btn btn-primary">
                            {
                                loading
                                  ?
                                  <span className="indicator-progress" style={{ display: 'block' }}>
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                  </span>
                                  :
                                  <span className="indicator-label">Submit</span>
                              }
                            </button>
                            </div>
                        </form>
                        <div className="text-center small text-primary">
                            <p className="mb-0"><a href="" onClick={() => navigate(appRoutes.loginPageRoute)} className="text-primary text-decoration-underline">Login</a></p>
                        </div>
                       

                        
                    </div>
                    <img className="bottom-left position-absolute" src="/assets/images/bottom-left-shape.png" alt="Bottom Left Shape" />
                </div>
            </div>
        </div>
  )
}

export default ForgotPassword
