import React, { useState, useEffect } from 'react';
import { sendRequest } from "../../../apis/APIs";
import { useNavigate, useParams } from 'react-router-dom';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { handleApiResponse } from "../../../apis/apiUtils";
import { useSelector, useDispatch } from 'react-redux';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import appRoutes from '../../../configs/AppRoutes';
import { validateUpdateBucket } from "./UpdateBucketValidation";

const Update = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, pageData } = useSelector((state) => state.common);
    const [fields, setFields] = useState([{ id: '', title: '' }]);
    const [bucketData, setBuckedData] = useState({});
    const [errorsInfo, setErrorsInfo] = useState({});

    useEffect(() => {
        getBucketData();
    }, []);

    const getBucketData = async () => {
        try {
            dispatch(callCommonAction({ loading: true }));
            const reqUrl = `/user/bucket-list/${params.id}`;
            const res = await sendRequest(reqUrl, "GET");
            setBuckedData(res.data.data);
            setFields(res.data.data.items || []);
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
        } catch (error) {
            console.log(error.response ? error.response.data : error.message, "error");
            toast.error('Something went wrong while fetching data');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    const handleFieldChange = (index, fieldName, value) => {
        const updatedFields = fields.map((field, i) =>
            i === index ? { ...field, [fieldName]: value } : field
        );
        setFields(updatedFields);
    };

    const handleAddField = () => {
        setFields([...fields, { id: '', title: '' }]);
    };

    const handleRemoveField = (index) => {
        const updatedFields = fields.filter((_, i) => i !== index);
        setFields(updatedFields);
    };

    const validateFields = () => {
        const errors = {};
        if (!bucketData.title) {
            errors.title = 'Title is required';
        }
        fields.forEach((field, index) => {
            if (!field.title) {
                errors[`itemTitle-${index}`] = `Item Title ${index + 1} is required`;
            }
        });
        setErrorsInfo(errors);
        return Object.keys(errors).length === 0;
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        
        // Validate before updating
        if (!validateFields()) {
            return;
        }

        const updatedData = {
            id: params.id,
            title: bucketData.title,
            itemsTitle: fields.map(item => item.title),
        };

        try {
            dispatch(callCommonAction({ loading: true }));
            const reqUrl = `/user/bucket-list/update/${updatedData.id}`;
            const res = await sendRequest(reqUrl, "POST", updatedData);
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);

            if (res.data.type === "success") {
                navigate(`${appRoutes.viewPageDetailPageRoute}/${pageData.id}`);
            } else {
                toast.error(res.data.message || 'Failed to update list');
            }
        } catch (error) {
            console.error("Error updating bucket list:", error);
            toast.error('Something went wrong while updating data');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    return (
        <div>
            <div className="form-container">
                <form className="create-page-form py-3" encType="multipart/form-data" onSubmit={handleUpdate}>
                    <div className="mb-3">
                        <FloatingLabel controlId="floatingTitle" label="List Title">
                            <Form.Control
                                className="input-field form-control"
                                type="text"
                                name="title"
                                placeholder="Enter Bucket list title"
                                value={bucketData.title || ''}
                                onChange={(e) => {
                                    setBuckedData((prev) => ({
                                        ...prev,
                                        title: e.target.value,
                                    }));
                                }}
                            />
                            <div className="error-message">{errorsInfo.title}</div>
                        </FloatingLabel>
                    </div>
                    {fields.map((field, index) => (
                        <div key={index} className="mb-0 form-group d-flex align-items-center">
                            <FloatingLabel controlId={`floatingItemTitle-${index}`} label={`Item Title ${index + 1}`}>
                                <Form.Control
                                    className="input-field form-control mt-1"
                                    type="text"
                                    name="itemTitle"
                                    placeholder="Enter item title"
                                    value={field.title}
                                    onChange={(e) => handleFieldChange(index, 'title', e.target.value)}
                                />
                                <div className="error-message">{errorsInfo[`itemTitle-${index}`]}</div>
                            </FloatingLabel>

                            <Form.Control
                                type="hidden"
                                value={field.id}
                                onChange={(e) => handleFieldChange(index, 'id', e.target.value)}
                            />

                            <button
                                type="button"
                                onClick={() => handleRemoveField(index)}
                                className="btn btn-danger ms-2"
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                    <button type="button" onClick={handleAddField} className="btn btn-primary mt-3">
                        Add More
                    </button>

                    <div className="text-center mb-4 mt-2">
                        <Button className='w-100 btn-lg btn-primary shadow' type="submit" disabled={loading}>
                            {loading ? (
                                <span className="indicator-progress" style={{ display: 'block' }}>
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            ) : (
                                <span className="indicator-label">Update</span>
                            )}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Update;
