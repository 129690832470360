import React, { useState, useEffect } from 'react';
import { sendRequest } from "../../../apis/APIs";
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import moment from "moment";
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { handleApiResponse } from "../../../apis/apiUtils";
import { useSelector, useDispatch } from 'react-redux';
import appRoutes from '../../../configs/AppRoutes';
const List = () => {
    const [upcommingPlans, setUpcommingPlans] = useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, pageData } = useSelector((state) => state.common);

    useEffect(() => {
      getUpcommingPlans();
      }, []);
    
      const getUpcommingPlans = async () => {
        try {
          let reqUrl = `/user/page/` + pageData.id + `/upcomming-plans`;
          const res = await sendRequest(reqUrl, "GET");
          if (res.data.type === "success") {
            setUpcommingPlans(res.data.data);
          }
        } catch (error) {
          console.log('Something went wrong');
        }
      };
    
      const deletePlan = async (planId) => {
        try {
    
          let reqUrl = `/user/upcomming-plans/delete/` + planId;
          dispatch(callCommonAction({ loading: true }));
          const res = await sendRequest(reqUrl, "DELETE");
          dispatch(callCommonAction({ loading: false }));
          handleApiResponse(res);
          if (res.data.type === "success") {
            getUpcommingPlans();
          }
        } catch (error) {
          console.log('Something went wrong');
        }
      };
  return (
    <div>
      <div className='px-3'>
        <div className="title-header">
          <div>
            <h5 className="mb-0"><i className="fa-solid fa-list-check"></i> Upcomming Plans</h5>
          </div>       

        </div>
        <div className='mb-2'>
                        <button className='btn add-item-btn w-100' onClick={() => navigate(appRoutes.upcomingEventsCreatePageRoute)}> <img className='me-2' src={process.env.PUBLIC_URL + '/assets/images/plus.svg'} /> Add plan</button>
                    </div>
        <div className='listing'>
          {upcommingPlans && upcommingPlans.length > 0 ? (
            upcommingPlans.map((item, index) => (
              <div className='list-item' key={index}>
                <div className='d-flex align-items-center'>
                  <div className='w-100'>
                    <h2>{item.title}</h2>
                    <p className='m-0'><a href="#" className='link'><i className="fa-solid fa-calendar me-1"></i> ({moment(item.date_time).format("D MMM YYYY")})</a></p>
                  </div>
                </div>
                <div>
                  <Dropdown className='custom-dropdown'>
                    <Dropdown.Toggle as="button" className="btn">
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {
                        user.id === item.created_by ?
                          <>
                            <Dropdown.Item onClick={() => navigate(appRoutes.upcomingEventsUpdatePageRoute + '/' + item.id)}><i className="fa-solid fa-pencil me-1"></i> Edit</Dropdown.Item>
                            <Dropdown.Item onClick={() => deletePlan(item.id)}><i className="fa-solid fa-times me-1" ></i> Delete</Dropdown.Item>
                          </>
                          : ''
                      }

                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            ))
          ) : (
            <p>No Data Available</p>
          )}
        </div>
      </div>
      <div className="mt-4">
        <p onClick={() => navigate(pageData ? appRoutes.viewPageDetailPageRoute + '/' + pageData.id : appRoutes.selectPageTypePageRoute)} className='text-center small text-primary text-decoration-underline pointer'><i className="fa-solid fa-chevron-left small"></i> Back</p>
      </div>
    </div>
  )
}

export default List
