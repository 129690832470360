import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { validateEventFormData } from "./Validation";
import { sendRequest } from "../../../apis/APIs";
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import { useDropzone } from 'react-dropzone';
import appRoutes from '../../../configs/AppRoutes';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { handleApiResponse } from '../../../apis/apiUtils';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import debounce from 'lodash.debounce';

const Create = () => {

    // Define the API endpoint
    const params = useParams();

    const GEOCODING_API_URL = process.env.REACT_APP_GEOCODING_API_URL;
    const dispatch = useDispatch();
    const { loading, pageData } = useSelector((state) => state.common);
    const [errorsInfo, setErrorsInfo] = useState({});
    const navigate = useNavigate();
    const [suggestions, setSuggestions] = useState([]);
    const [value, setValue] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [placeId, setPlaceId] = useState('');
    const [files, setFiles] = useState([]);
    const [savedFiles, setSavedFiles] = useState([]);
    const [joindMembers, setJoindMembers] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [eventDetail, setEventDetail] = useState({ name: '', description: '', date_time: '', location: '', lattitude: '', longitude: '', members_invited: '' });

    useEffect(() => {
        if (params.id) {
            getEventDetail();
        }
        getPageData();
        getJoindMembers();
    }, []);

    const getEventDetail = async () => {
        try {
            let reqUrl = `/user/page/` + pageData.id + '/event/' + params.id;
            const res = await sendRequest(reqUrl, "GET");
            if (res.data.type === "success") {
                setEventDetail({
                    ...eventDetail,
                    name: res.data.data.name,
                    description: res.data.data.description,
                    date_time: res.data.data.date_time,
                    location: res.data.data.location,
                    lattitude: res.data.data.lattitude,
                    longitude: res.data.data.longitude,
                    members_invited: res.data.data.members_invited,
                });
                let selectedOptions = res.data.data.members_invited.split(',');
                setSelectedOptions(selectedOptions);
                setSavedFiles(res.data.data.galleries);

            }
        } catch (error) {
            toast.error('Something went wrong');
        }
    };

    const handleChangeOptions = (event) => {
        // Get the selected options as an array of values
        const options = Array.from(event.target.selectedOptions, option => option.value);
        // Convert array to comma-separated string and store in state
        setSelectedOptions(options);
        let commaSeparatedOptions = options.join(',');
        setEventDetail({ ...eventDetail, members_invited: commaSeparatedOptions });
    };
    const selectedArray = selectedOptions;
    const getPageData = async () => {
        // e.preventDefault();
         //if (validateOtp()) {
             const res = await sendRequest(`/user/get-page-data`, "GET",);
             if (res.data.type === "success") {
                 
                 if (res.data.page_data) {
                     dispatch(callCommonAction({ pageData: res.data.page_data }));
                     localStorage.setItem('pageData', JSON.stringify(res.data.page_data));
                     //navigate(appRoutes.viewPageDetailPageRoute + '/' + res.data.page_data.id);
                 } 
                
 
             } else {
                 //toast.error(res.data.message || 'Otp not valid');
             }
         //}
     };
    const getJoindMembers = async () => {
        try {
            let reqUrl = `/user/joind-members`;
            const res = await sendRequest(reqUrl, "POST", { member_ids: pageData.members_joined });
            if (res.data.type === "success") {
                setJoindMembers(res.data.data);
            }
        } catch (error) {
            //toast.error('Something went wrong');
        }
    };

    // Handle file addition
    const handleDrop = (acceptedFiles) => {
        setFiles((prevFiles) => [
            ...prevFiles,
            ...acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            ),
        ]);
        //({ ...eventDetail, files: acceptedFiles });
    };

    // Handle file removal
    const handleRemove = (file) => {
        setFiles((prevFiles) =>
            prevFiles.filter((f) => f !== file)
        );
        URL.revokeObjectURL(file.preview); // Clean up the URL object
    };

    const deleteGallery = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "Do you really want to delete this gallery? This action cannot be undone.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        });
    
        if (result.isConfirmed) {
            try {
                // Start loading
                dispatch(callCommonAction({ loading: true }));
    
                // Prepare API URL
                const reqUrl = `/user/event/gallery/delete/${id}`;
    
                // Perform API call
                const res = await sendRequest(reqUrl, "DELETE");
    
                // Stop loading
                dispatch(callCommonAction({ loading: false }));
    
                // Handle API response
                handleApiResponse(res);
    
                // If successful, navigate to another page
                if (res.data.type === "success") {
                    getEventDetail();
                }
            } catch (error) {
                // Handle errors
                console.error('Something went wrong', error);
                dispatch(callCommonAction({ loading: false }));
                //toast.error('Something went wrong');
            }
        } else {
            // If user cancels, do nothing
            console.log("Deletion canceled.");
        }
    };
    

    // Dropzone configuration
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleDrop,
        multiple: true,
        accept: 'image/*',
    });
    const fetchSuggestions = async (value) => {
        try {
            const response = await axios.get(GEOCODING_API_URL, {
                params: {
                    q: value,
                    format: 'json',
                    addressdetails: 1,
                    limit: 5,
                },
            });
            const results = response.data.map((item) => ({
                label: item.display_name,
                id: item.place_id,
                location: {
                    lat: item.lat,
                    lon: item.lon,
                },
            }));
            setSuggestions(results);
        } catch (error) {
            console.error('Error fetching address suggestions:', error);
        }
    };
 
    

    // Handle input changes
    const onChange = (event, { newValue }) => {
        setValue(newValue);
        setEventDetail({ ...eventDetail, location: newValue });
    
    };
    // Handle suggestion selection
    const onSuggestionSelected = (event, { suggestion }) => {
        setValue(suggestion.label);
        setLatitude(suggestion.location.lat);
        setLongitude(suggestion.location.lon);
        setPlaceId(suggestion.id);
        setEventDetail({ ...eventDetail, location: suggestion.label, lattitude: suggestion.location.lat, longitude: suggestion.location.lon });
        setErrorsInfo({ ...errorsInfo, location: '' });

    };

    // Render suggestion
    const renderSuggestion = (suggestion) => (
        <div>{suggestion.label}</div>
    );

    // Get suggestion value
    const getSuggestionValue = (suggestion) => suggestion.label;

    const handleChange = (e) => {
        eventDetail[e.target.name] = e.target.value;
        setEventDetail(eventDetail);
        checkFormIsValid(e.target.name);
    };

    /** Validation implemented here */
    const checkFormIsValid = async () => {
        const res = validateEventFormData(eventDetail);
        setErrorsInfo(res.errors);
        //console.log('setErrorsInfo', errorsInfo, await checkFormIsValid(""))
        return res.formVaildCheck;
    };

    const saveEventDetail = async (e) => {
        e.preventDefault();
        if (await checkFormIsValid("")) {
            try {
                const formData = new FormData();
                Object.keys(eventDetail).forEach((key) => {
                    formData.append(key, eventDetail[key]);
                });
                //const filesArray = Array.from(eventDetail.files);
                if(files.length > 0){
                    files.forEach(file => {
                        formData.append('files[]', file);
                    });
                }
                
                

                dispatch(callCommonAction({ loading: true }));
                let reqUrl = params.id ? `/user/page/` + pageData.id + `/event/update/`+params.id: `/user/page/` + pageData.id + `/event/create`;
                const res = await sendRequest(reqUrl, "POST", formData);
                dispatch(callCommonAction({ loading: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    navigate(appRoutes.viewPageDetailPageRoute + '/' + pageData.id);
                }
            } catch (error) {
                //toast.error('Something went wrong');
                dispatch(callCommonAction({ loading: false }));
            }
        }
    };


    return (
        <div className='outer bg-lightBrown' >
            <div className="form-container">
                <form className="create-page-form" encType="multipart/form-data">
                    <div className="icon-picture">
                        <i className="fa-solid fa-user-group"></i>
                    </div>
                    <div className="text-center mb-11">
                        <h1 className="title mb-4">{params.id ? 'Update' : 'Create'} Event</h1>
                    </div>

                    <div className="mb-3">
                        <FloatingLabel controlId="floatingDate" label="Event Name">
                            <Form.Control
                                className="input-field form-control"
                                type="text"
                                placeholder="Enter group name"
                                name="name"
                                value={eventDetail.name}
                                onChange={handleChange}
                            />
                        </FloatingLabel>
                        <div className="error-message">{errorsInfo.name}</div>
                    </div>
                   

                    <div className="mb-3">
                        <FloatingLabel controlId="floatingDatetime" label="Event Date & Time">
                            <Form.Control
                                className="input-field form-control"
                                type="datetime-local"
                                name="date_time"
                                placeholder="Select date and time"
                                value={eventDetail.date_time}
                                onChange={handleChange}
                            />
                        </FloatingLabel>
                        <div className="error-message">{errorsInfo.date_time}</div>
                    </div>

                    <div className="mb-3">
                        <Autosuggest
                        className=""
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={({ value }) => fetchSuggestions(value)}
                            onSuggestionsClearRequested={() => setSuggestions([])}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={{
                                placeholder: 'Type an address', name: 'location', value,onChange,
                            }}
                            onSuggestionSelected={onSuggestionSelected}
                        />
                        <input type="hidden" name="latitude" value={eventDetail.lattitude} />
                        <input type="hidden" name="longitude" value={eventDetail.longitude} />
                        {/* <input type="hidden" name="place_id" value={eventDetail.placeId} /> */}

                        <div className="error-message">{errorsInfo.location}</div>
                    </div>

                    <div className="mb-3">
                        <Form.Select className="input-field form-control" multiple aria-label="Default select example" onChange={handleChangeOptions} value={selectedArray} name="members_invited">
                            <option>Open this select menu</option>
                            {
                                joindMembers.length > 0 ? (
                                    joindMembers.map((user) => (
                                        <option key={user.id} value={user.id}>
                                            {user.name}
                                        </option>
                                    ))
                                ) : (
                                    <option value="">No options available</option>
                                )
                            }
                        </Form.Select>
                        <div className="error-message">{errorsInfo.members_invited}</div>
                    </div>

                    <div className="mb-3">
                        <FloatingLabel controlId="floatingDate" label="Event Description">
                            <Form.Control
                                as="textarea"
                                className="input-field form-control"
                                placeholder="Enter event description"
                                name="description"
                                value={eventDetail.description}
                                onChange={handleChange}
                                style={{ height: '100px' }} // Adjust the height as needed
                            />
                        </FloatingLabel>
                        <div className="error-message">{errorsInfo.description}</div>
                    </div>

                    <div className="image-upload">
                        {/* Dropzone area */}
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <p>Drag & drop some files here, or click to select files</p>
                        </div>

                        {/* Image previews */}
                        <div className="image-previews">
                            {savedFiles && savedFiles.length > 0 && savedFiles.map((file, index) => (
                                <div key={index} className="image-preview">
                                    <img src={file.file ? process.env.REACT_APP_ASSET_ENDPOINT + '/' + file.file : ''} alt={file.file} />
                                    <button type="button" className="remove-button" onClick={() => deleteGallery(file.id)}>×</button>
                                </div>
                            ))}
                            {files.map((file) => (
                                <div key={file.name} className="image-preview">
                                    <img src={file.preview} alt={file.name} />
                                    <button className="remove-button" onClick={() => handleRemove(file)}>×</button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="text-center mb-4">
                        <Button className='w-100 btn-lg btn-primary shadow' disabled={loading} onClick={saveEventDetail}>
                            {loading ? (
                                <span className="indicator-progress" style={{ display: 'block' }}>
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            ) : (
                                <span className="indicator-label">{params.id ? 'Update' : 'Create'}</span>
                            )}
                        </Button>
                    </div>
                    <div className="">
                        <p  onClick={() => navigate(pageData ? appRoutes.viewPageDetailPageRoute+'/'+pageData.id : appRoutes.selectPageTypePageRoute)} className='text-center small text-primary text-decoration-underline pointer'>
                            <i className="fa-solid fa-chevron-left small"></i> Back
                        </p>
                    </div>
                </form>
            </div>
        </div >
    );
};

export default Create;
