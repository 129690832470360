import STRINGS from '../../../common/strings/strings';
import Helper from '../../../apis/Helper';

export function validateEventFormData(eventDetail, type, id=null) {
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;

        const {name, description, date_time, location, members_invited} = eventDetail;
        if (name === '' && id == null) {
            errorMsg = name === '' ? STRINGS.nameReq : '';
            errors.name = errorMsg;
            formVaildCheck = false;
        }
        if (description === '') {
            errorMsg = description === '' ? STRINGS.descriptionReq : '';
            errors.description = errorMsg;
            formVaildCheck = false;
        }       
        if (date_time === '' && id == null) {
            errorMsg = date_time === '' ? STRINGS.eventDateReq : '';
            errors.date_time = errorMsg;
            formVaildCheck = false;
        }
        if (location === '') {
            errorMsg = location === '' ? STRINGS.locationReq : '';
            errors.location = errorMsg;
            formVaildCheck = false;
        }    
        if (members_invited === '') {
            errorMsg = members_invited === '' ? STRINGS.membersInvitedReq : '';
            errors.members_invited = errorMsg;
            formVaildCheck = false;
        }       


    return { formVaildCheck: formVaildCheck, errors: errors };
}