import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { sendRequest } from "../../../apis/APIs";
import { toast } from 'react-toastify';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import TopBackButton from '../../../common/TopBackButton';
import { Dropdown } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { handleApiResponse } from '../../../apis/apiUtils';

const View = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const [eventDetail, setEventDetail] = useState({});
    const { loading, pageData, user } = useSelector((state) => state.common);
    const [pageCover, setPageCover] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [images, setImages] = useState([]);
    const [note, setNote] = useState('');

    useEffect(() => {
        if (params.id) {
            getEventDetail();
        }
    }, [params.id]);

    const getEventDetail = async () => {
        try {
            const reqUrl = `/user/page/${pageData.id}/event/${params.id}`;
            const res = await sendRequest(reqUrl, "GET");
            if (res.data.type === "success") {
                const data = res.data.data;
                setEventDetail(data);
                setPageCover(
                    data.favorite_gallery && data.favorite_gallery.file
                        ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${data.favorite_gallery.file}`
                        : `${process.env.PUBLIC_URL}/assets/images/placeholder-img.png`
                );
                setImages(
                    data.galleries
                        ? data.galleries.map(file => `${process.env.REACT_APP_ASSET_ENDPOINT}/${file.file}`)
                        : []
                );
            } else {
                toast.error(res.data.message || 'Failed to fetch event details');
            }
        } catch (error) {
            toast.error('Something went wrong while fetching event details');
            console.error(error);
        }
    };

    const galleriesUpdate = async (id, status) => {
        try {
            const reqUrl = `/user/gallery/make-favorite/${id}/${status}`;
            const res = await sendRequest(reqUrl, "GET");
            if (res.data.type === "success") {
                toast.success(res.data.message);
                getEventDetail();
            } else {
                toast.error(res.data.message || 'Failed to update gallery');
            }
        } catch (error) {
            toast.error('Something went wrong while updating gallery');
            console.error(error);
        }
    };

    const deleteGallery = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "Do you really want to delete this gallery? This action cannot be undone.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            try {
                const reqUrl = `/user/event/gallery/delete/${id}`;
                const res = await sendRequest(reqUrl, "DELETE");
                handleApiResponse(res);
                if (res.data.type === "success") {
                    // toast.success(res.data.message || 'Gallery deleted successfully');
                    getEventDetail();
                } else {
                    toast.error(res.data.message || 'Failed to delete gallery');
                }
            } catch (error) {
                toast.error('Something went wrong while deleting gallery');
                dispatch(callCommonAction({ loading: false }));
            }
        } else {
            // If user cancels, do nothing
            console.log("Deletion canceled.");
        }
    };

    const handleChange = (e) => {
        setNote(e.target.value);
    };

    const saveNote = async (e) => {
        e.preventDefault();
        if (note.trim() !== '') {
            try {
                dispatch(callCommonAction({ loading: true }));
                const reqUrl = `/user/event/${params.id}/note/add`;
                const res = await sendRequest(reqUrl, "POST", { note });
                dispatch(callCommonAction({ loading: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    // toast.success(res.data.message || 'Comment added successfully');
                    getEventDetail();
                    setNote('');
                } else {
                    toast.error(res.data.message || 'Failed to add comment');
                    setNote('');
                }
            } catch (error) {
                toast.error('Something went wrong while adding comment');
                dispatch(callCommonAction({ loading: false }));
                console.error(error);
            }
        } else {
            toast.error('Please enter a comment');
        }
    };

    return (
        <div>
            {/* Header Section */}
            <div className="t-4 position-relative">
                <TopBackButton />
                <div className='page-detail-header shadow' style={{ backgroundImage: `url(${pageCover})` }}>
                    <div className='caption mb-2 px-3'>
                        <h1>{eventDetail.name || 'Event Name'}</h1>
                        <div className="fs-12 text-white">
                            <p>{eventDetail.location || 'Event Location'}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className='px-3'>
                <div>
                    <div className="title-header mb-0">
                        <div>
                            <h5 className="mb-0"><i class="fa-solid fa-list-ul"></i> Event Detail</h5>
                        </div>
                    </div>
                    <div>
                        <p className='text-body-secondary small lh-base fw-light'>{eventDetail.description}</p>
                    </div>
                </div>

                {/* Galleries Section */}
                <div className="title-header">
                    <h5 className="mb-0"><i className="fa-solid fa-images"></i> Galleries</h5>
                </div>
                <div className="image-previews">
                    {eventDetail.galleries && eventDetail.galleries.length > 0 ? (
                            eventDetail.galleries.map((file) => (
                                <div key={file.id} className="image-preview">
                                    {
                                        file.favorite === 1 && (
                                            <button className='btn btn-link fav-star p-0'>
                                                <i className="fa-solid fa-star"></i>
                                            </button>
                                        )
                                    }
                                    <img
                                        src={file.file ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${file.file}` : `${process.env.PUBLIC_URL}/assets/images/placeholder-img.png`}
                                        alt={file.file ? `Gallery Image ${file.id}` : 'Placeholder Image'}
                                        onClick={() => { setIsOpen(true); setPhotoIndex(eventDetail.galleries.findIndex(f => f.id === file.id)); }}
                                    />
                                    <div className='end-0 position-absolute top-0 z-3'>
                                        <Dropdown className='custom-dropdown'>
                                            <Dropdown.Toggle as="button" className="btn">
                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {
                                                    file.favorite === 0 ? (
                                                        <Dropdown.Item onClick={() => galleriesUpdate(file.id, 1)}>
                                                            <i className="fa-solid fa-check me-1"></i> Mark as favorite
                                                        </Dropdown.Item>
                                                    ) : (
                                                        <Dropdown.Item onClick={() => galleriesUpdate(file.id, 0)}>
                                                            <i className="fa-solid fa-xmark me-1"></i> Remove as favorite
                                                        </Dropdown.Item>
                                                    )
                                                }
                                                <Dropdown.Item onClick={() => deleteGallery(file.id)}>
                                                    <i className="fa-solid fa-trash me-1"></i> Delete
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No Galleries yet</p>
                        )
                    }
                </div>

                {/* Lightbox for Opening Images */}
                {isOpen && images.length > 0 && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                    />
                )}

                {/* Comments Section */}
                <div className='chat-section pb-5 mt-4'>
                    <div>
                        <div className="title-header mb-4">
                            <div>
                                <h5 className="mb-0"><i className="fa-regular fa-comment-dots"></i> Comments</h5>
                                <p className="mb-0">You can send your comment here</p>
                            </div>
                        </div>

                        <div className='chat-messages'>
                            {
                                eventDetail.notes && eventDetail.notes.length > 0 ? (
                                    eventDetail.notes.map((note) => (
                                        <div key={note.id} className={note.user_id !== user.id ? "chat-bubble" : "chat-bubble right"}>
                                            {note.user_id !== user.id ? (
                                                <>
                                                    <img src={note.created_by?.profile_picture ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${note.created_by.profile_picture}` : `${process.env.PUBLIC_URL}/assets/images/user-default.jpg`} alt={note.created_by?.name} />
                                                    <div className="chat-message">
                                                        {note.note}
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="chat-message">
                                                        {note.note}
                                                    </div>
                                                    <img src={note.created_by?.profile_picture ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${note.created_by.profile_picture}` : `${process.env.PUBLIC_URL}/assets/images/user-default.jpg`} alt={note.created_by?.name} />
                                                </>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <p>No comments yet</p>
                                )
                            }
                        </div>

                        {/* Comment Input */}
                        <div className='align-items-center d-flex gap-2 mt-3'>
                            <textarea
                                name="note"
                                value={note}
                                onChange={handleChange}
                                className='form-control shadow message-box'
                                rows={3}
                                placeholder='Type a comment'
                            ></textarea>
                            <button
                                className="btn rounded-circle shadow send-msg"
                                disabled={loading}
                                onClick={saveNote}
                            >
                                <i className="fa-regular fa-paper-plane"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default View;
