import React, { useEffect, useState } from 'react';
import { gapi } from 'gapi-script';
import { sendRequest } from "../../../apis/APIs";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import axios from "axios";

const SCOPES = 'https://www.googleapis.com/auth/calendar';
const CALENDLY_CLIENT_ID = process.env.REACT_APP_CALENDLY_CLIENT_ID; // Store in .env
const CALENDLY_CLIENT_SECRET = process.env.REACT_APP_CALENDLY_CLIENT_SECRET;
const CALENDLY_REDIRECT_URI = encodeURIComponent('http://localhost:3000/page/calander-synchroniz'); // Ensure correct URI
const CLIENT_ID_Google = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const Synchroniz = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [upcommingPlans, setUpcommingPlans] = useState([]);
  const [eventsId, setEventsId] = useState([]);

  const { pageData, loading } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    getUpcommingPlans();
  }, []);

  useEffect(() => {
    const start = () => {
      gapi.client.init({
        clientId: CLIENT_ID_Google,
        scope: SCOPES,
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
      }).then(() => {
        const authInstance = gapi.auth2.getAuthInstance();
        if (authInstance) {
          setIsSignedIn(authInstance.isSignedIn.get());
          authInstance.isSignedIn.listen(setIsSignedIn);
        } else {
          console.error('Auth instance is undefined');
        }
      }).catch((error) => {
        console.error('Error initializing Google API client:', error);
      });
    };

    gapi.load('client:auth2', start);
  }, []);

  const getUpcommingPlans = async () => {
    try {
      let reqUrl = `/user/page/` + pageData.id + `/upcomming-plan-by-status`;
      const res = await sendRequest(reqUrl, "GET");
      if (res.data.type === "success") {
        setUpcommingPlans(res.data.data);
      }
    } catch (error) {
      console.log('Something went wrong');
    }
  };

  const handleLogin = () => {
    gapi.auth2.getAuthInstance().signIn().then((user) => {
      console.log("User signed in:", user);
      handlePostToGoogleCalendar();
    }).catch((error) => {
      console.error('Error during Google sign-in:', error);
    });
  };

  const handlePostToGoogleCalendar = async () => {
    const accessToken = gapi.auth.getToken().access_token; // Get the access token
    if (!accessToken) {
      console.error('No access token available for Google Calendar');
      return;
    }

    if (upcommingPlans.length === 0) {
      toast.error("No upcoming plans to post to Google Calendar.");
      return;
    }

    try {
      const postedEventIds = [];
      const eventPromises = upcommingPlans.map(async (event) => {
        const eventBody = {
          summary: event.title,
          start: {
            dateTime: new Date(event.date_time).toISOString(),
          },
          end: {
            dateTime: new Date(event.date_time).toISOString(),
          },
          description: event.description,
        };

        dispatch(callCommonAction({ loading: true }));

        return gapi.client.calendar.events.insert({
          calendarId: 'primary',
          resource: eventBody,
        }).then(() => {
          postedEventIds.push(event.id);
        });
      });

      await Promise.all(eventPromises);
      dispatch(callCommonAction({ loading: false }));
      toast.success("All events posted successfully to Google Calendar");
      await sendPostedEventIdsToAPI(postedEventIds);
    } catch (error) {
      console.error('Error creating event in Google Calendar:', error);
      toast.error('An error occurred while posting events to Google Calendar.');
      dispatch(callCommonAction({ loading: false }));
    }
  };

  const handleCalendlyAuth = () => {
    const authorizationUrl = `https://calendly.com/oauth/authorize?response_type=code&client_id=${CALENDLY_CLIENT_ID}&redirect_uri=${CALENDLY_REDIRECT_URI}`;
    window.location.href = authorizationUrl; // Redirect user to Calendly for authorization
  };

  const handleCalendlySync = async (code) => {
    try {
      const redirect_uri = 'http://localhost:3000/page/calander-synchroniz';
      const response = await fetch('https://auth.calendly.com/oauth/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          client_id: CALENDLY_CLIENT_ID,
          client_secret: CALENDLY_CLIENT_SECRET,
          code: code,
          grant_type: 'authorization_code',
          redirect_uri: redirect_uri,
          scope: 'default',
        }).toString(),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error in response:', errorData);
        return;
      }

      const data = await response.json();
      console.log('Access Token:', data.access_token);
      setAccessToken(data.access_token);
      await handlePostToCalendly(data.access_token); // Post to Calendly after getting access token
    } catch (error) {
      console.error('Error during Calendly sync:', error);
    }
  };

  const handlePostToCalendly = async (accessToken) => {
    try {
      const postedEventIds = [];
      const staticData = {
        location: "Office", // Static location
        attendees: [
          { email: "example@example.com" } // Use an object for attendees
        ],
        duration: 60, // Static duration in minutes
        name: "Meeting Title", // Add a name for the event
        start_time: "2024-10-15T10:00:00Z", // Example start time in ISO 8601 format
      };
  
      console.log('Posting event:', staticData); // Log the event being posted
      const response = await fetch('https://api/event_types/${uuid}', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(staticData),
      });
  
      if (response.ok) {
        const data = await response.json();
        postedEventIds.push(data.id); // Assuming the ID is in the response
        console.log('Event posted successfully:', data);
      } else {
        const errorData = await response.json();
        console.error('Error creating event in Calendly:', errorData);
        toast.error(`Error ${response.status}: ${errorData.message}`); // Provide detailed error feedback
      }
  
      toast.success("All events posted successfully to Calendly");
    } catch (error) {
      console.error('Error posting events to Calendly:', error);
      toast.error('An unexpected error occurred while posting events.');
    }
  };
  



  const handleCalendlySyncTrigger = (event) => {
    event.preventDefault();
    handleCalendlyAuth(); // Start Calendly OAuth flow
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      console.log('Authorization code received:', code); // Debugging log
      handleCalendlySync(code); // Call the function to exchange the code for a token
    }
  }, []);

  const sendPostedEventIdsToAPI = async (postedEventIds) => {
    try {
      console.log(postedEventIds, "postedEventIds");
      const reqUrl = '/user/update-sync-upcomming-data';
      const res = await sendRequest(reqUrl, 'POST', { eventIds: postedEventIds });
      if (res.data.type === "success") {
        console.log("Posted Event IDs sent successfully:", res.data);
      } else {
        console.error("Error sending Posted Event IDs:", res.data.message);
      }
    } catch (error) {
      console.error('Error sending Posted Event IDs:', error);
    }
  };

  return (
    <div>
      <button className='btn add-item-btn w-100 mb-2' onClick={handleCalendlySyncTrigger}>Calendly</button>
      <button className='btn add-item-btn w-100' onClick={handleLogin} disabled={loading}>
        {loading ? (
          <span className="indicator-progress" style={{ display: 'block' }}>
            Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <span className="indicator-label">Google Calendar</span>
        )}
      </button>
    </div>
  );
};

export default Synchroniz;
