import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendRequest } from "../../../apis/APIs";
import { handleApiResponse } from "../../../apis/apiUtils";
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { useNavigate, useLocation } from 'react-router-dom';
import { FloatingLabel, Form } from 'react-bootstrap';
import appRoutes from '../../../configs/AppRoutes';

const Otp = () => {
    const [otp, setOtp] = useState('');
    const [errorsInfo, setErrorsInfo] = useState({});
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { loading } = useSelector((state) => state.common);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        setOtp(value);
        if (errorsInfo.otp) {
            setErrorsInfo({ ...errorsInfo, otp: '' });
        }
    };

    const validateOtp = () => {
        let isValid = true;
        let errors = {};

        if (!otp || otp.trim() === '') {
            errors.otp = 'OTP is required';
            isValid = false;
        } else if (otp.length !== 4) {
            errors.otp = 'OTP must be 4 digits';
            isValid = false;
        }
        setErrorsInfo(errors);
        return isValid;
    };

    const otpUserSubmit = async (e) => {
        e.preventDefault();
        if (validateOtp()) {
            const res = await sendRequest(`/user/verify-otp`, "POST", { otp });
            handleApiResponse(res);
            if (res.data.type === "success") {
                setOtp('');
                localStorage.setItem("token", res.data.token);
                localStorage.setItem('user', JSON.stringify(res.data.data));
                localStorage.setItem('isLoggedIn', true);
                dispatch(callCommonAction({ isLoggedIn: true, user: res.data.data, token: res.data.token, pageData: res.data.page_data }));
                if (res.data.page_data) {
                    localStorage.setItem('pageData', JSON.stringify(res.data.page_data));
                    navigate(appRoutes.viewPageDetailPageRoute + '/' + res.data.page_data.id);
                } else {
                    navigate(appRoutes.selectPageTypePageRoute);
                }

            } else {
                toast.error(res.data.message || 'Otp not valid');
            }
        }
    };

    const resendOtp = async (e) => {
        let email = localStorage.getItem("email");
        const res = await sendRequest(`/user/resend-otp`, "POST", { email });
        handleApiResponse(res);
    };

    return (
        <div className="otp-container">
            <div className="siteWidth">
                <div className="d-flex flex-column justify-content-center outer bg-light position-relative">
                    <img className="top-right position-absolute" src="/assets/images/top-right-shape.png" alt="Top Right Shape" />
                    <div className="form-container">
                        <div className="text-center mb-4">
                            <h1 className="title mb-4">Enter OTP</h1>
                            <p className="mb-2 text-center small text-primary">We have sent a One-Time Password (OTP) to your email address.</p>
                            <p className="mb-5 text-center small text-primary">Please enter it below to verify your identity.</p>
                        </div>

                        <form className="otp-form" noValidate onSubmit={otpUserSubmit}  >
                            <div className="mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="OTP"
                                >
                                    <Form.Control className="input-field form-control" type="text"
                                        placeholder="Enter your OTP"
                                        name="otp"
                                        value={otp}
                                        onChange={handleChange} />
                                </FloatingLabel>
                                <div className="error-message">{errorsInfo.otp}</div>

                            </div>
                            <div className="text-center mb-4">
                                <button onSubmit={otpUserSubmit} disabled={loading ? true : false} className="w-100 btn-lg btn-primary shadow btn btn-primary">
                                    {
                                        loading
                                            ?
                                            <span className="indicator-progress" style={{ display: 'block' }}>
                                                Please wait...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                            :
                                            <span className="indicator-label">Verify OTP</span>
                                    }
                                </button>
                            </div>
                        </form>



                        <div className="text-center small text-primary">
                            <p className="mb-0">Didn't receive the OTP? <a href="#" onClick={resendOtp} className="text-primary text-decoration-underline">Resend OTP</a></p>
                        </div>
                    </div>
                    <img className="bottom-left position-absolute" src="/assets/images/bottom-left-shape.png" alt="Bottom Left Shape" />
                </div>
            </div>
        </div>
    );
};

export default Otp;
