import React, { useState, useEffect, useRef } from 'react';
import { sendRequest } from "../../apis/APIs";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { Accordion, Button, Modal, FloatingLabel, Form } from 'react-bootstrap';
import moment from "moment";
import { formatDistanceToNow, format } from 'date-fns';
import appRoutes from '../../configs/AppRoutes';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { EffectCards, Navigation } from 'swiper/modules';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { handleApiResponse } from '../../apis/apiUtils';
import { Pagination } from 'swiper/modules';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { toast } from 'react-toastify';
import HTMLFlipBook from "react-pageflip";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for selectable events
import { validateMusicValidation } from "./MusicValidation";
import Swal from 'sweetalert2';

const View = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pageDetail, setPageDetail] = useState({});
    const [difference, setDifference] = useState("");
    const defaultPosition = [51.505, -0.09]; // Example coordinates
    const [message, setMessage] = useState('');
    const { loading, pageData, user, loading1 } = useSelector((state) => state.common);
    const [eventData, setEventData] = useState({});
    const [shoMusicPopup, setShowMusicPopup] = useState(false);
    const [errorsInfoMusic, setErrorsInfoMusic] = useState({});
    const [musicData, setMusicData] = useState({});
    const zoomLevel = 5;
    const [activeMapIndex, setActiveMapIndex] = useState(0);
    const [checkedItems, setCheckedItems] = useState({});
    const bookRef = useRef(null);
    const [events, setEvents] = useState([]);
    const [formData, setFormData] =
        useState({});
    const [errorsInfo, setErrorsInfo] = useState({});

    // Function to handle flips
    const handleFlip = (e) => {
        // Ensure bookRef.current is defined
        if (bookRef.current) {
            console.log("Page flipped");
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (pageData && pageData.id !== params.id) {
                navigate(appRoutes.viewPageDetailPageRoute + '/' + pageData.id);
            } else {
                navigate(appRoutes.homePageRoute);
            }

            await getPageDetail();             // Await for the page detail 
        };
        fetchData();  // Call the inner async function

    }, []);
    useEffect(() => {

        getUpcomingEventData();      // Await for the upcoming event data

    }, [pageDetail]);

    const getUpcomingEventData = () => {
        let newEvents = [];

        if (pageDetail.upcomming_plan && pageDetail.upcomming_plan.length > 0) {
            newEvents = pageDetail?.upcomming_plan?.map(plan => ({
                id: plan.id || formData.title,
                title: plan.date_night_roulette.title || formData.title,
                start: plan.date_time || formData.startDate,
                end: plan.date_time || formData.endDate,
                description: plan.description || formData.description,
            }));
        } else {
            // Define the newEvent if upcomming_plan is empty
            const newEvent = {
                title: formData.title,
                start: formData.startDate,
                end: formData.endDate,
                description: formData.description,
            };
            newEvents = [newEvent];
        }
        setEvents((prevEvents) => [...prevEvents, ...newEvents]);
        setFormData({ title: '', description: '', startDate: '', endDate: '' });
    };


    const getPageDetail = async () => {
        try {
            let reqUrl = params.id === pageData.id ? `/user/page/` + params.id : `/user/page/` + pageData.id;
            const res = await sendRequest(reqUrl, "GET");
            if (res.data.type === "success") {
                setPageDetail(res.data.data);
                if (res.data.data && res.data.data.events && res.data.data.events.length > 0) {
                    setEventData(res.data.data.events[0]);
                }
                //if (res.data.data.type === 1) {
                    const startDate = moment(res.data.data.anniversary_date);
                    const now = moment();
                    const years = now.diff(startDate, 'years');
                    const months = now.diff(startDate.add(years, 'years'), 'months');

                    setDifference(`${years} years and ${months} months`);
                //}
            }
        } catch (error) {
            console.log('Something went wrong');
        }
    };
    const handleChange = (e) => {
        setMessage(e.target.value);
    };

    const getEventData = (event, index) => {
        setActiveMapIndex(index);
        setEventData(event);
    };
    const sendMessage = async (e) => {
        e.preventDefault();
        if (message.trim() !== '') {
            try {
                dispatch(callCommonAction({ loading1: true }));
                const reqUrl = `/user/page/${pageDetail.id}/message/send`;
                const res = await sendRequest(reqUrl, "POST", { message });
                dispatch(callCommonAction({ loading1: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    getPageDetail();
                    setMessage('');
                } else {
                    toast.error(res.data.message || 'Failed to sent message');
                    setMessage('');
                }
            } catch (error) {
                toast.error('Something went wrong while sending message');
                dispatch(callCommonAction({ loading1: false }));
                console.error(error);
            }
        } else {
            toast.error('Please enter a message');
        }
    };

    const handleCloseMusicPopup = () => setShowMusicPopup(false);
    const handleShowMusicPopup = () => setShowMusicPopup(true);

    const handleChangeForMusic = (e) => {
        musicData[e.target.name] = e.target.value;
        setMusicData(musicData);
        checkFormIsValid(e.target.name);

    };

    const checkFormIsValid = async (fieldName) => {
        const res = validateMusicValidation(musicData, fieldName);
        if (fieldName) {
            fieldName = fieldName.trim();
            const err = res.errors[fieldName];
            if (typeof err !== "undefined") {
                errorsInfo[fieldName] = err;
            } else {
                errorsInfo[fieldName] = "";
            }
            setErrorsInfo({ ...errorsInfo });
        } else {
            setErrorsInfo({ ...res.errors });
        }

        return res.formValidCheck;
    };

    const saveMusicData = async (e) => {
        e.preventDefault();
        if (await checkFormIsValid("")) {

            try {
                dispatch(callCommonAction({ loading: true }));
                let reqUrl = `/user/page/` + params.id + `/music/create`;
                const res = await sendRequest(reqUrl, "POST", musicData);
                dispatch(callCommonAction({ loading: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    getPageDetail();
                    setShowMusicPopup(false);
                    setMusicData({});
                } else {
                    toast.error(res.data.message || 'Failed to add music');
                    setMessage('');
                }
            } catch (error) {
                toast.error('Something went wrong while sending message');
                dispatch(callCommonAction({ loading: false }));
                console.error(error);
            }
        }
    };

    const handleScrollToSection = (e, sectionId, offset) => {
        e.preventDefault(); // Prevent default anchor behavior
        const section = document.getElementById(sectionId);

        if (section) {
            const sectionPosition = section.getBoundingClientRect().top + window.scrollY; // Get the section position relative to the top
            const offsetPosition = sectionPosition - offset; // Subtract the offset (50px)

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth' // Smooth scroll
            });
        }
    };

    const groupIntoPairs = (arr) => {
        const result = [];
        for (let i = 0; i < arr.length; i += 2) {
            result.push(arr.slice(i, i + 2));
        }
        return result;
    };
    // Handle checkbox change
    const handleCheckboxChange = async (id, type, isChecked) => {
        //e.preventDefault();
        let status = 0;
        if (isChecked) {
            status = 1;
        } else {
            status = 0;
        }
        let postData = { id: id, type: type, status: status };
        try {
            dispatch(callCommonAction({ loading: true }));
            let reqUrl = `/user/bucket-list/status/update`;
            const res = await sendRequest(reqUrl, "POST", postData);
            dispatch(callCommonAction({ loading: false }));
            // Only handle the API response if status is not 0
            if (status !== 0) {
                handleApiResponse(res);
            }
            if (res.data.type === "success") {
                getPageDetail();
            } else {
                toast.error(res.data.message || 'Failed to update status');
                setMessage('');
            }
        } catch (error) {
            toast.error('Something went wrong while sending message');
            dispatch(callCommonAction({ loading: false }));
            console.error(error);
        }
    };
    
    const deleteMusic = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "Do you really want to delete this MUSIC? This action cannot be undone.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        });
    
        if (result.isConfirmed) {
            try {
                // Start loading
                dispatch(callCommonAction({ loading: true }));
    
                // Prepare API URL
                const reqUrl = `/user/music/delete/${id}`;
    
                // Perform API call
                const res = await sendRequest(reqUrl, "DELETE");
    
                // Stop loading
                dispatch(callCommonAction({ loading: false }));
    
                // Handle API response
                handleApiResponse(res);
    
                // If successful, navigate to another page
                if (res.data.type === "success") {
                    getPageDetail();
                }
            } catch (error) {
                // Handle errors
                console.error('Something went wrong', error);
                dispatch(callCommonAction({ loading: false }));
                //toast.error('Something went wrong');
            }
        } else {
            // If user cancels, do nothing
            console.log("Deletion canceled.");
        }
    };
    const renderEventContent = (eventInfo) => {
        return (
            <div>
                {/* Only display event title */}
                <b>{eventInfo.event.title}</b>
            </div>
        );
    };

    const handleEventClick = (info) => {
        navigate(appRoutes.upcomingEventsUpdatePageRoute + '/' + info.event.id);
        // You can use `info.event` to get event details and trigger any action here
        //alert(`Event clicked: ${info.event.title}`);
        // You can also navigate to another page or show a modal
    };

    return (
        <>
            <div className="hero-section">
                <div className="hero-header">
                    <div className='hero-img-sec'>
                        <img className='hero-img' src={process.env.REACT_APP_ASSET_ENDPOINT + '/' + pageDetail.cover_picture} />
                        <img className='blur' src={process.env.PUBLIC_URL + '/assets/images/blur.png'} />
                    </div>
                    <div className='hero-bottom position-relative z-1'>
                        <div className="hero-details d-flex justify-content-center align-items-center">

                            <img
                                src={pageDetail.created_by?.profile_picture
                                    ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${pageDetail.created_by.profile_picture}`
                                    : `${process.env.PUBLIC_URL}/assets/images/florian.png`}
                                alt={pageDetail.created_by?.name || 'Default Name'}
                            />
                            <img
                                className='right'
                                src={pageDetail.member_joined && pageDetail.member_joined.length > 0 && pageDetail.member_joined[0]?.created_by?.profile_picture
                                    ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${pageDetail.member_joined[0].created_by.profile_picture}`
                                    : `${process.env.PUBLIC_URL}/assets/images/jooliett.png`}
                                alt="Profile Picture"
                            />
                        </div>
                        <div className="hero-info">
                            <h3>
                                {pageDetail.type === 1
                                    ? (
                                        <>
                                            {pageDetail.created_by?.name}
                                            <span> ∞ </span>
                                            {pageDetail.member_joined && pageDetail.member_joined.length > 0 && pageDetail.member_joined[0]?.created_by?.name
                                                ? pageDetail.member_joined[0].created_by.name
                                                : '-'}
                                        </>
                                    )
                                    : pageDetail.name}
                            </h3>
                            <p>({difference})</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='tabs-section'>
                <div className='container-fluid px-4'>
                    <ul className="flex-nowrap mt-4 nav nav-pills overflow-x-auto custom-scrollbar pb-1">
                        <li className="nav-item">
                            <a className="nav-link" href="#gallery" onClick={(e) => handleScrollToSection(e, 'gallery', 50)}>Our Gallery</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#timeline" onClick={(e) => handleScrollToSection(e, 'timeline', 50)}>Timeline</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#globe" onClick={(e) => handleScrollToSection(e, 'globe', 50)}>Interactive Globe</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#chat" onClick={(e) => handleScrollToSection(e, 'chat', 50)}>Chat</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#music" onClick={(e) => handleScrollToSection(e, 'music', 50)}>Music</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#bucket" onClick={(e) => handleScrollToSection(e, 'bucket', 50)}>Bucket List</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#calender" onClick={(e) => handleScrollToSection(e, 'calender', 50)}>Our Calender</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#scrapbook" onClick={(e) => handleScrollToSection(e, 'scrapbook', 50)}>Scrapbook</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div id='gallery' className='gallery-section'>
                <div className='px-3'>
                    <div className="title-header mb-4">
                        <div>
                            <h5 className="mb-0">Our Gallery</h5>
                            <p className="mb-0">You can check your gallery here</p>
                        </div>
                    </div>
                    <div className='mb-5'>
                        <Swiper
                            effect={'cards'}
                            grabCursor={true}
                            modules={[EffectCards, Navigation]}
                            navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                        >
                            {pageDetail.events && pageDetail.events.length > 0 ? (
                                pageDetail.events.map((event, index) => {
                                    // Calculate the "time ago" string for each event
                                    const dateStr = event.date_time; // Replace with your actual date string from event data
                                    const date = new Date(dateStr);
                                    const timeAgo = formatDistanceToNow(date, { addSuffix: true });

                                    return (
                                        <SwiperSlide key={index} onClick={() => navigate(appRoutes.viewEventPageRoute + '/' + event.id)}>
                                            <img
                                                src={event?.favorite_gallery?.file ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${event.favorite_gallery.file}` : process.env.PUBLIC_URL + '/assets/images/placeholder-img.png'}
                                                alt={event.name || 'Event Image'}
                                            />
                                            <div className='gallery-time'>{timeAgo}</div>
                                            <div className='gallery-memory'>
                                                <h2 className='gallery-name'>- {event.name} ♥</h2>
                                                <h4 className='gallery-location'>({event.location})</h4>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })
                            ) : (
                                <h6 className='text-center'>No Event Found</h6>
                            )}
                            <div className='actions d-flex align-items-center justify-content-center gap-2 mt-4'>
                                <div className="swiper-button swiper-button-next">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/arrow-long-right.png'} />
                                </div>
                                <div className="swiper-button swiper-button-prev">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/arrow-long-right.png'} />
                                </div>
                            </div>
                        </Swiper>
                    </div>
                </div>
            </div>
            <div className=''>
                <img src={process.env.PUBLIC_URL + '/assets/images/shape-155.svg'} />
            </div>
            <div id='timeline' className='timeline-section'>
                <div className='px-3'>
                    <div className="title-header mb-4">
                        <div>
                            <h5 className="mb-0">Timeline</h5>
                            <p className="mb-0">You can check your events here</p>
                        </div>
                    </div>
                </div>
                <div className='mt-5'>
                    <Swiper
                        slidesPerView={2}
                        spaceBetween={20}
                        centeredSlides={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        modules={[Pagination, Navigation]}
                    >
                        {pageDetail.events && pageDetail.events.length > 0 ? (
                            pageDetail.events.map((event, index) => {
                                // Calculate the "time ago" string for each event
                                const dateStr = event.date_time; // Replace with your actual date string from event data
                                const date = new Date(dateStr);
                                const timeAgo = format(date, 'dd MMMM yyyy');

                                return (
                                    <SwiperSlide key={index}>
                                        <div className='timeline-card' onClick={() => navigate(appRoutes.viewEventPageRoute + '/' + event.id)}>
                                            <h2>{event.name} ✈</h2>
                                            <div className='timeline-image'>
                                                <img src={event?.favorite_gallery?.file ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${event.favorite_gallery.file}` : process.env.PUBLIC_URL + '/assets/images/placeholder-img.png'} alt={event.name} />
                                            </div>
                                            <div className='timeline-info'>
                                                <h3 className='limited-text'>{event.location}</h3>
                                                <p>{timeAgo}</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                );
                            })
                        ) : (
                            <h6 className='text-center'>No Event Found</h6>
                        )}
                        <div className='actions d-flex align-items-center justify-content-center gap-2 mt-4'>
                            <div className="swiper-button swiper-button-next">
                                <img src={process.env.PUBLIC_URL + '/assets/images/arrow-long-right.png'} />
                            </div>
                            <div className="swiper-button swiper-button-prev">
                                <img src={process.env.PUBLIC_URL + '/assets/images/arrow-long-right.png'} />
                            </div>
                        </div>
                    </Swiper>
                </div>
            </div>
            <div className='mt-4'>
                <img src={process.env.PUBLIC_URL + '/assets/images/shape-1583.svg'} />
            </div>
            <div id='globe' className='map-section'>
                <div className='px-3'>
                    <div className="title-header mb-4">
                        <div>
                            <h5 className="mb-0">Our Globe Map</h5>
                            <p className="mb-0">You can check your events here</p>
                        </div>
                    </div>
                </div>
                <div className='position-relative'>
                    <div style={{ height: "500px", width: "100%" }}>
                        <MapContainer center={defaultPosition} zoom={zoomLevel} style={{ height: "100%", width: "100%" }}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"  // URL of tile service
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' // Attribution for legal requirements
                                maxZoom={19}  // Maximum zoom level
                                minZoom={3}   // Minimum zoom level
                                tileSize={256}  // Size of tiles
                                zoomOffset={0}  // Zoom offset
                            />
                            {pageDetail.events && pageDetail.events.length > 0 && pageDetail.events.map(marker => (

                                <Marker
                                    key={marker.id}
                                    position={[marker?.lattitude, marker?.longitude]}
                                    icon={new L.Icon({
                                        iconUrl: process.env.PUBLIC_URL + '/assets/images/map-pin-icon.png',
                                        iconSize: [45, 45],
                                        iconAnchor: [16, 45],
                                        popupAnchor: [0, -45],
                                    })}
                                >
                                    <Popup>
                                        <img src={marker?.favorite_gallery?.file ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${marker.favorite_gallery.file}` : process.env.PUBLIC_URL + '/assets/images/placeholder-img.png'} />
                                        <p className='text-decoration-underline fw-bold' onClick={() => navigate(appRoutes.viewEventPageRoute + '/' + marker.id)}>{marker.name}</p>
                                        <p className='limited-text'>{marker.description}</p>
                                    </Popup>
                                </Marker>

                            ))}
                        </MapContainer>
                    </div>
                    <div className='map-img-scroller'>
                        {pageDetail.events && pageDetail.events.length > 0 ? (
                            pageDetail.events.map((event, index) => {
                                return (
                                    <div key={index} id={`image_item_${index}`} className={`scroller-img ${activeMapIndex === index ? 'active' : ''}`} onClick={() => getEventData(event, index)}>
                                        <img
                                            src={event.favorite_gallery && event.favorite_gallery.file
                                                ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${event.favorite_gallery.file}`
                                                : process.env.PUBLIC_URL + '/assets/images/map-img-1.jpg'}
                                            alt={event.name || 'Event Image'}
                                        />

                                    </div>
                                );
                            })
                        ) : (
                            <h6 className='text-center'>No Event Found</h6>
                        )}
                    </div>
                    {
                        eventData ?
                            <div className='map-img-text px-3 mt-5'>
                                <h4 className='d-inline-flex'><img className='me-1' src={process.env.PUBLIC_URL + '/assets/images/map-pin.svg'} /> {eventData.location} - {moment(eventData.date_time).format('DD/MM/YYYY')}</h4>
                                <p>{eventData.description}</p>
                            </div> :
                            null
                    }
                </div>
            </div>

            <div className='mt-4'>
                <img src={process.env.PUBLIC_URL + '/assets/images/shape-1584.svg'} />
            </div>
            <div id='chat' className='chat-section'>
                <div className='px-3'>
                    <div className="title-header mb-4">
                        <div>
                            <h5 className="mb-0">Love Notes</h5>
                            <p className="mb-0">You can send your love notes here</p>
                        </div>
                    </div>

                    <div className='chat-section' style={{ overflowY: 'auto', height: '230px', }}>
                        {
                            pageDetail.chat && user && pageDetail.chat.length > 0 ? (
                                pageDetail.chat.map((chatData) => (
                                    <div key={chatData.id} className={chatData.sender_id !== user.id ? "chat-bubble" : "chat-bubble right"}>
                                        {chatData.sender_id !== user.id ? (
                                            <>
                                                <img src={chatData.send_by?.profile_picture ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${chatData.send_by.profile_picture}` : `${process.env.PUBLIC_URL}/assets/images/user-default.jpg`} alt={chatData.send_by?.name} />
                                                <div className="chat-message">
                                                    {chatData.message}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="chat-message">
                                                    {chatData.message}
                                                </div>
                                                <img src={chatData.send_by?.profile_picture ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${chatData.send_by.profile_picture}` : `${process.env.PUBLIC_URL}/assets/images/user-default.jpg`} alt={chatData.send_by?.name} />
                                            </>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p>No Message yet</p>
                            )
                        }
                    </div>

                    <div className='align-items-center d-flex gap-2 mt-3'>
                        <textarea
                            name="note"
                            value={message}
                            onChange={handleChange}
                            className='form-control shadow message-box'
                            rows={3}
                            placeholder='Type a comment'
                        ></textarea>
                        <button
                            className="btn rounded-circle shadow send-msg"
                            disabled={loading1}
                            onClick={sendMessage}
                        >
                            <i className="fa-regular fa-paper-plane"></i>
                        </button>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div className='mt-4'>
                <img src={process.env.PUBLIC_URL + '/assets/images/shape-1583.svg'} />
            </div>
            {/* Modal Component */}
            <Modal show={shoMusicPopup} onHide={handleCloseMusicPopup} centered>
                <Modal.Header closeButton>
                    <Modal.Title className='fs-5 text-primary'>Add New Song</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-container">
                        <form className="create-page-form py-3" encType="multipart/form-data">
                            <div className="mb-3">
                                <FloatingLabel controlId="floatingDate" label="Music Title">
                                    <Form.Control
                                        className="input-field form-control"
                                        type="text"
                                        placeholder="Enter music title"
                                        name="title"
                                        value={musicData.title}
                                        onChange={handleChangeForMusic}
                                    />
                                    <div className="error-message">{errorsInfo.title}</div>

                                </FloatingLabel>
                            </div>
                            <div className="mb-0">
                                <FloatingLabel controlId="floatingDate" label="Music Url">
                                    <Form.Control
                                        className="input-field form-control"
                                        type="text"
                                        placeholder="Enter music url"
                                        name="url"
                                        value={musicData.url}
                                        onChange={handleChangeForMusic}
                                    />
                                    <div className="error-message">{errorsInfo.url}</div>
                                </FloatingLabel>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="" className='btn-sm' onClick={handleCloseMusicPopup}>
                        Close
                    </Button>
                    <Button variant="primary" className='btn-sm' onClick={saveMusicData}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <div id='music' className='music-section'>
                <div className='px-3'>
                    <div className="title-header mb-4">
                        <div>
                            <h5 className="mb-0">Music</h5>
                            <p className="mb-0">You can check your events here</p>
                        </div>
                        <div className="title-icons flex-shrink-0">
                            <button className="btn btn-light" onClick={handleShowMusicPopup}>
                                <img style={{ width: '24px' }} src={process.env.PUBLIC_URL + '/assets/images/plus-icon.svg'} />
                            </button>
                        </div>
                    </div>
                </div>

                <div>
                    <Swiper
                        slidesPerView={2}
                        spaceBetween={20}
                        centeredSlides={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        modules={[Pagination, Navigation]}
                    >

                        {pageDetail.musics && pageDetail.musics.length > 0 ? (
                            pageDetail.musics.map((music, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div className='music-card'>
                                        <Button variant="link" className="btn btn-sm me-1 mt-1 position-absolute end-0" onClick={() => {deleteMusic(music.id);}}>
                                            <i className="fa-solid fa-trash small text-danger"></i></Button>
                                            <a href={music.url} target='_blank' className='text-decoration-none text-body'>
                                                <div className='music-image'>
                                                    <img src={process.env.PUBLIC_URL + '/assets/images/sunrise.jpg'} alt="" />
                                                </div>
                                                <div className='music-info'>
                                                    <div className='mb-1'>
                                                        <h3 className=''>{music.title}</h3>
                                                        <h6 className='ms-auto mb-0'>{moment(music.created_at).format('DD/MM/YYYY HH:mm')}</h6>
                                                    </div>
                                                    {/* <p className='text-start'>Alan walker ,James Bond</p> */}
                                                </div>
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                );
                            })
                        ) : (
                            <h6 className='text-center'>No Music Found</h6>
                        )}


                        <div className='actions d-flex align-items-center justify-content-center gap-2 mt-4'>
                            <div className="swiper-button swiper-button-next">
                                <img src={process.env.PUBLIC_URL + '/assets/images/arrow-long-right.png'} />
                            </div>
                            <div className="swiper-button swiper-button-prev">
                                <img src={process.env.PUBLIC_URL + '/assets/images/arrow-long-right.png'} />
                            </div>
                        </div>
                    </Swiper>
                </div>
            </div>
            <div className='mt-4'>
                <img src={process.env.PUBLIC_URL + '/assets/images/shape-1584.svg'} />
            </div>

            <div id='bucket' className='bucket-section'>
                <div className='px-3'>
                    <div className="title-header mb-4">
                        <div>
                            <h5 className="mb-0">Bucket List</h5>
                            <p className="mb-0">You can check your Bucket list here</p>
                        </div>

                    </div>
                    <Accordion defaultActiveKey="0">
                        {pageDetail.bucket_list && pageDetail.bucket_list.length > 0 ? (
                            pageDetail.bucket_list.map((bucket, bucketIndex) => (
                                <Accordion.Item key={bucketIndex} eventKey={bucketIndex.toString()}>
                                    <Accordion.Header>
                                        {bucket.items.every(item => item.status === 1) && (
                                            <img className='me-2' src={process.env.PUBLIC_URL + '/assets/images/check-circle.svg'} alt="Icon" />
                                        )}
                                        {bucket.title} {/* Use dynamic title */}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className='d-flex flex-column'>
                                            {bucket.items.map((item, itemIndex) => (
                                                <div key={itemIndex} className="d-flex align-items-center justify-content-between bucket-item">
                                                    <div>
                                                        <h5
                                                            className={`mb-1 ${item.status === 1 ? 'line-through' : ''}`}
                                                        >
                                                            {item.title}
                                                        </h5>
                                                        <p className="mb-0">({moment(item.created_at).format("D MMM YYYY")}, created by {item.created_by.name})</p>
                                                    </div>
                                                    <div className="title-icons flex-shrink-0">
                                                        <label htmlFor={`bucket_item_${bucketIndex}_${itemIndex}`}>
                                                            <input
                                                                id={`bucket_item_${bucketIndex}_${itemIndex}`}
                                                                type="checkbox"
                                                                name={`option_${bucketIndex}`}
                                                                value={`option_${bucketIndex}_${itemIndex}`}
                                                                checked={item.status === 1 ? true : false}
                                                                onChange={(e) =>
                                                                    handleCheckboxChange(item.id, 'item', e.target.checked)
                                                                }
                                                            />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </Accordion.Body>
                                </Accordion.Item>
                            ))
                        ) : (
                            <h6 className='text-center'>No Bucket List Found</h6>
                        )}
                    </Accordion>


                    <div className='mt-3'>
                        <button className='btn add-item-btn w-100' onClick={() => navigate(appRoutes.bucketListCreatePageRoute)}> <img className='me-2' src={process.env.PUBLIC_URL + '/assets/images/plus.svg'} /> Add Another List</button>
                    </div>
                </div>
            </div>

            <div className='mt-4'>
                <img src={process.env.PUBLIC_URL + '/assets/images/shape-1584.svg'} />
            </div>

            <div id='calender' className=''>
                <div className='px-3'>
                    <div className="title-header mb-4">
                        <div>
                            <h5 className="mb-0">Our Calender</h5>
                            <p className="mb-0">You can check your calender here</p>
                        </div>
                    </div>
                    <div className='mb-4"'>
                        <FullCalendar
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            selectable={true}
                            events={events} // Pass events to FullCalendar
                            eventContent={renderEventContent} // Custom rendering
                            eventClick={handleEventClick} // Handle click events on the event
                            headerToolbar={{
                                left: 'prev,next', // Controls for previous, next, and today buttons
                                center: 'title', // Displays the current view title
                                right: 'dayGridMonth,timeGridWeek' // Custom view options: month, week, and day views
                              }}
                              buttonText={{
                                //today: 'Today',
                                month: 'Month',
                                week: 'Week',
                                //day: 'Day'
                              }}
                              dayHeaderFormat={{ weekday: 'long' }} // Change day format (e.g., "Monday")
                              eventTimeFormat={{ hour: '2-digit', minute: '2-digit', hour12: false }} // Custom event time format

                        />
                          {/* <FullCalendar
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                initialView="timeGridWeek"
                                headerToolbar={false} // Hide the default header for custom header
                                events={events} // Custom events array
                                contentHeight="auto" // Responsive height for mobile
                                eventContent={renderEventContent} // Custom event content (images, time)
                                eventClick={handleEventClick}
                                allDaySlot={false} // This disables the all-day section

                            /> */}
                    </div>
                </div>
            </div>

            <div id='scrapbook' className='scrapbook-section'>
                <div className="title-header mb-4 justify-content-center text-center">
                    <div>
                        <h5 className="mb-0">Scrapbook</h5>
                        <p className="mb-0">You can check your calender here</p>
                    </div>
                </div>
                {pageDetail.events && pageDetail.events.length > 0 ? (
                    <div className="flip-book-container">
                        <HTMLFlipBook
                            width={300}
                            height={500}
                            size="fixed"
                            minWidth={150}
                            maxWidth={600}
                            minHeight={200}
                            maxHeight={800}
                            drawShadow={true}
                            mobileScrollSupport={true}
                            className="flip-book"
                            ref={bookRef}
                            onFlip={handleFlip}
                            // Ensure that the flipbook does not show both pages
                            showCover={true}  // Enable showing the cover page
                            showEndPage={true} // Enable showing the end page
                            useMouseEvents={true} // Enable mouse events for better interaction
                        >
                            {/* Cover Page */}
                            <div className="cover-page">
                                <div className="cover-text">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/book-logo.png'} />
                                    <h1>Our Memories Scrapbook</h1>
                                    <p>Our Memories</p>
                                </div>
                            </div>

                            {/* Regular Pages */}
                            {
                                pageDetail?.events?.flatMap((event, eventIndex) => {
                                    // Page for event details
                                    const eventPage = (
                                        <div key={`event-${eventIndex}`} className="page">
                                            <div className="event-main">
                                                <div className='book-event-cover'>
                                                    <img
                                                        src={event.favorite_gallery && event.favorite_gallery.file
                                                            ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${event.favorite_gallery.file}`
                                                            : `${process.env.PUBLIC_URL}/assets/images/map-img-1.jpg`}
                                                        alt={event.name || 'Event Image'}
                                                    />
                                                </div>
                                                <div className="book-content">
                                                    <h2>{event.name}</h2>
                                                    <div className="inner">
                                                        <p>{event.description}</p>
                                                    </div>
                                                    <p className='fw-bold mb-0 book-event-location d-inline-flex align-items-start gap-1'> <img src={process.env.PUBLIC_URL + '/assets/images/map-pin.png'} /> {event.location}</p>
                                                </div>
                                            </div>
                                        </div>
                                    );

                                    // Group the original array into pairs
                                    const groupedArray = groupIntoPairs(event.galleries);

                                    // Pages for galleries within this event
                                    const galleryPages = groupedArray.length > 0
                                        ? groupedArray.map((eventGallery, galleryIndex) => (

                                            <div key={`gallery-${eventIndex}-${galleryIndex}`} className="page">
                                                <div className="book-event-gallery">
                                                    <h2 className='title'><i className="fa-solid fa-images me-2 small"></i> Event Gallery</h2>
                                                    <div className="event-gallery">
                                                        <img
                                                            src={eventGallery[0].file
                                                                ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${eventGallery[0].file}`
                                                                : `${process.env.PUBLIC_URL}/assets/images/map-img-1.jpg`}
                                                            alt={eventGallery.name || 'Gallery Image'}
                                                        />

                                                    </div>
                                                    {eventGallery[1] ?
                                                        <div className="event-gallery">
                                                            <img
                                                                src={eventGallery[1].file
                                                                    ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${eventGallery[1].file}`
                                                                    : `${process.env.PUBLIC_URL}/assets/images/map-img-1.jpg`}
                                                                alt={eventGallery.name || 'Gallery Image'}
                                                            />

                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                        ))
                                        : []; // Return an empty array instead of a page with a message

                                    // Pages for notes within this event
                                    const notePages = event.notes.length > 0
                                        ? [
                                            <div key={`note-page-${eventIndex}`} className="page"> {/* Wrap all notes in a single page */}
                                                <div className='p-3'>
                                                    <h2 className='title'><i className="fa-regular fa-comment-dots me-2 small"></i> Event Note</h2>
                                                    {user && event.notes.map((note, noteIndex) => (
                                                        <div key={`note-${eventIndex}-${noteIndex}`} className="event-note">
                                                            <div className={note.user_id !== user.id ? "chat-bubble" : "chat-bubble right"}>
                                                                {note.user_id !== user.id ? (
                                                                    <>
                                                                        <img
                                                                            src={note.created_by?.profile_picture
                                                                                ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${note.created_by.profile_picture}`
                                                                                : `${process.env.PUBLIC_URL}/assets/images/user-default.jpg`}
                                                                            alt={note.created_by?.name}
                                                                        />
                                                                        <div className="chat-message">
                                                                            {note.note}
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div className="chat-message">
                                                                            {note.note}
                                                                        </div>
                                                                        <img
                                                                            src={note.created_by?.profile_picture
                                                                                ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${note.created_by.profile_picture}`
                                                                                : `${process.env.PUBLIC_URL}/assets/images/user-default.jpg`}
                                                                            alt={note.created_by?.name}
                                                                        />
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>


                                                    ))}
                                                </div>
                                            </div>
                                        ]
                                        : [];


                                    // Check if there are no galleries and no notes to display a "No Galleries Found" message
                                    const noGalleriesMessage = event.galleries.length === 0 && event.notes.length === 0
                                        ? (
                                            <div key={`no-content-${eventIndex}`} className="page">
                                                <h6 className='text-center'>No Galleries or Notes Found for {event.name}</h6>
                                            </div>
                                        )
                                        : null;

                                    // Return the event page followed by gallery pages, note pages, and a possible no content message
                                    return [eventPage, ...galleryPages, ...notePages, noGalleriesMessage].filter(Boolean);
                                })
                            }

                            {/* End Page */}
                            <div className="end-page">
                                <div className="d-flex h-100 justify-content-center align-items-center flex-column book-end-text">
                                    <h1>End Page</h1>
                                    <p>Thank you for viewing</p>
                                </div>
                            </div>
                        </HTMLFlipBook >
                    </div>
                ) : (
                    <h6 className='text-center'>No Event Found</h6>
                )}
            </div>
        </>
    );
};
const renderEventContent = (eventInfo) => (
    <div className="event-content">
      <img src={eventInfo.event.extendedProps.imageUrl} alt="Event" className="event-image" />
      <div className="event-details">
        <h4>{eventInfo.event.title}</h4>
        <p>{eventInfo.event.extendedProps.time}</p>
      </div>
    </div>
  );
  
export default View;