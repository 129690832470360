import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { sendRequest } from "../../apis/APIs";
import { handleApiResponse } from "../../apis/apiUtils";
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import appRoutes from '../../configs/AppRoutes';
import { validatePageFormData } from "./Validation";
import { toast } from 'react-toastify';

const CreateUpdateCouple = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [pageDetail, setPageDetail] = useState({ type: 1, cover_picture: '', anniversary_date: '' });
    const [errorsInfo, setErrorsInfo] = useState({});
    const dispatch = useDispatch();
    const { loading, pageData } = useSelector((state) => state.common);
    const [fileName, setFileName] = useState(''); // State to store the file name
    const [filePreview, setFilePreview] = useState(null); // State to store the file preview URL

    useEffect(() => {
        if (params.id) {
            getPageDetail();
        }
    }, []);

    const getPageDetail = async () => {
        try {
            let reqUrl = `/user/page/` + params.id;
            const res = await sendRequest(reqUrl, "GET");
            if (res.data.type === "success") {
                setPageDetail({
                    ...pageDetail,
                    anniversary_date: res.data.data.anniversary_date,
                    cover_picture: res.data.data.cover_picture,
                });
                setFilePreview(process.env.REACT_APP_ASSET_ENDPOINT + '/' + res.data.data.cover_picture);
            }
        } catch (error) {
            toast.error('Something went wrong');
        }
    };


    const handleFileChange = (e) => {
        const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
        if (file) {
            setFileName(file.name); // Update the file name in state
            setFilePreview(URL.createObjectURL(file)); // Create a preview URL and update the state
            setPageDetail({
                ...pageDetail, // Spread the existing properties of pageDetail
                cover_picture: file // Update the anniversary_date property
            });
        }
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setPageDetail({
            ...pageDetail, // Spread the existing properties of pageDetail
            anniversary_date: value // Update the anniversary_date property
        });
        if (errorsInfo.anniversary_date) {
            setErrorsInfo({ ...errorsInfo, anniversary_date: '' });
        }
        checkFormIsValid(e.target.name);
    };

    /** Validation implemented here */
    const checkFormIsValid = async () => {
        const res = validatePageFormData(pageDetail, pageDetail.type);
        setErrorsInfo(res.errors);
        return res.formVaildCheck;
    };

    const savePageDetail = async (e) => {
        e.preventDefault();
        if (await checkFormIsValid("")) {
            try {
                const formData = new FormData();
                Object.keys(pageDetail).forEach((key) => {
                    formData.append(key, pageDetail[key]);
                });
                formData.append('cover_picture', filePreview);

                dispatch(callCommonAction({ loading: true }));
                let reqUrl = params.id ? `/user/page/update/` + params.id : `/user/page/create`;
                const res = await sendRequest(reqUrl, "POST", formData);
                dispatch(callCommonAction({ loading: false }));
                handleApiResponse(res);
                if (res.data.type === "success") {
                    localStorage.setItem('pageData', JSON.stringify(res.data.data));
                    dispatch(callCommonAction({ pageData: res.data.data }));
                    navigate(appRoutes.viewPageDetailPageRoute + '/' + res.data.data.id);
                }
            } catch (error) {
                //toast.error('Something went wrong');
                dispatch(callCommonAction({ loading: false }));
            }
        }
    };

    return (
        <div className='outer bg-lightBrown'>
            <div className="form-container">
                <form
                    className="create-page-form"
                    encType="multipart/form-data"
                    onSubmit={savePageDetail}>
                    <div className="icon-picture">
                        <i className="fa-solid fa-user-group"></i>
                    </div>
                    <div className="text-center mb-11">
                        <h1 className="title mb-4">{params.id ? 'Update' : 'Create'} Couple Page</h1>
                    </div>
                    {/* Image Upload Field */}
                    <div className="mb-3">
                        <Form.Group controlId="formFile" className="mb-3">
                            <div className='uploadedFile my-3 text-center'>
                                {filePreview && <img src={filePreview} alt={fileName} className="image-preview" />}
                                {fileName && <p className="file-name m-0">{fileName}</p>}
                            </div>
                            <label className="custom-file-upload">
                                <input
                                    className="input-field form-control"
                                    type="file"
                                    name="cover_picture"
                                    accept="image/*"
                                    onChange={handleFileChange} // Attach the onChange handler
                                />
                                <i className="fa-solid fa-upload"></i> Upload Image
                            </label>
                        </Form.Group>
                        <div className="error-message">{errorsInfo.cover_picture}</div>
                    </div>
                    {/* Date Picker Field */}
                    <div className="mb-3">
                        <FloatingLabel controlId="floatingDate" label="Select Your Anniversary Date">
                            <Form.Control
                                className="input-field form-control"
                                type="date"
                                placeholder="Select Your Anniversary Date"
                                name="anniversary_date"
                                value={pageDetail.anniversary_date}
                                onChange={handleChange}
                            />
                        </FloatingLabel>
                        <div className="error-message">{errorsInfo.anniversary_date}</div>
                    </div>
                    <div className="text-center mb-4">
                        <Button className='w-100 btn-lg btn-primary shadow' onClick={savePageDetail}
                            disabled={loading ? true : false}>
                            {
                                loading
                                    ?
                                    <span className="indicator-progress" style={{ display: 'block' }}>
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                    :
                                    <span className="indicator-label">{params.id ? 'Update' : 'Create'}</span>
                            }
                        </Button>
                    </div>
                    <div className="">
                        <p onClick={() => navigate(pageData ? appRoutes.viewPageDetailPageRoute + '/' + pageData.id : appRoutes.selectPageTypePageRoute)} className='text-center small text-primary text-decoration-underline pointer'><i className="fa-solid fa-chevron-left small"></i> Back</p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateUpdateCouple;
