import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Button, FloatingLabel, Form, Dropdown } from 'react-bootstrap';
import appRoutes from '../../../configs/AppRoutes';
import { useNavigate, useParams } from 'react-router-dom';
import { handleApiResponse } from "../../../apis/apiUtils";
import { toast } from 'react-toastify';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { sendRequest } from "../../../apis/APIs";

const CreateUpdate = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { loading, pageData } = useSelector((state) => state.common);
  const [errorsInfo, setErrorsInfo] = useState({});
  const [dateNightRoulette, setDateNightRoulette] = useState({});
  const [eventData, setEventData] = useState({id: '', title: '', roulette_id: '', date_time: '', description: '' });
  const [isFormVisible, setIsFormVisible] = useState(false); // State to toggle form visibility
  const [selectedOptions, setSelectedOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getDateNightRoulette();
    if(params.id){
      getUpcommingPlanById();
    }
  }, []);

  const getDateNightRoulette = async () => {
    try {
      let reqUrl = `/user/get-date-night-roulette`;
      const res = await sendRequest(reqUrl, "GET");
      if (res.data.type === "success") {
        setDateNightRoulette(res.data.data);
      }
    } catch (error) {
      console.log('Something went wrong');
    }
  };

  const getUpcommingPlanById = async () => {
    try {
      let reqUrl = `/user/upcomming-plan/${params.id}`;
      const res = await sendRequest(reqUrl, "GET");
      if (res.data.type === "success") {
        const date = new Date(res.data.data.date_time);
        const formattedDate = date.toISOString().split('T')[0];
        setEventData({
          ...eventData,
          id: res.data.data.id,
          title: res.data.data.title,
          roulette_id: res.data.data.roulette_id,
          date_time: formattedDate,
          description: res.data.data.description
        });
      }
    } catch (error) {
      console.log('Something went wrong');
    }
  };

  const handleChangeOptions = (event) => {
    // Get the selected options as an array of values
    const options = Array.from(event.target.selectedOptions, option => option.value);
    // Convert array to comma-separated string and store in state
    setSelectedOptions(options);
    setEventData({
      ...eventData,
      roulette_id: event.target.value,
    });
    let commaSeparatedOptions = options.join(',');
};
const selectedArray = selectedOptions;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEventData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(callCommonAction({ loading: true }));
      const reqUrl = `/user/page/upcoming/event/create-event`;
      const res = await sendRequest(reqUrl, "POST", eventData);
      dispatch(callCommonAction({ loading: false }));
      handleApiResponse(res);

      if (res.data.type === "success") {
        navigate(`${appRoutes.viewPageDetailPageRoute}/${pageData.id}`);
      } else {
        toast.error(res.data.message || 'Failed to update list');
      }
    } catch (error) {
      console.error("Error updating bucket list:", error);
      toast.error('Something went wrong while updating data');
      dispatch(callCommonAction({ loading: false }));
    }
  };

  const handleAddNewEvent = () => {
    setIsFormVisible(true); // Show the form fields when "Add New Event" is clicked
  };

  const createUpcommingEvent = async (e) => {
    e.preventDefault();
    try {
      dispatch(callCommonAction({ loading: true }));
      const reqUrl = `/user/page/${pageData.id}/upcomming-plans/create-and-update`;
      const res = await sendRequest(reqUrl, "POST", eventData);
      dispatch(callCommonAction({ loading: false }));
      handleApiResponse(res);

      if (res.data.type === "success") {
        navigate(`${appRoutes.upcomingEventsPageRoute}`);
      } else {
        toast.error(res.data.message || 'Failed to update list');
      }
    } catch (error) {
      console.error("Error updating bucket list:", error);
      toast.error('Something went wrong while updating data');
      dispatch(callCommonAction({ loading: false }));
    }
};


  return (
    <div className='outer bg-lightBrown'>
      <div className="form-container">
        <div className="text-center mb-11">
          <h1 className="title mb-4">{params.id ? 'Update' : 'Create'} Upcoming Event</h1>
        </div>
        
        <form className="create-page-form mt-3" encType="multipart/form-data" onSubmit={handleSubmit}>
          <div className="mb-3">
            <Form.Select className="input-field form-control" aria-label="Default select example" onChange={handleChangeOptions} value={selectedArray} name="members_invited">
              <option>Open this select menu</option>
              {
                dateNightRoulette.length > 0 ? (
                  dateNightRoulette.map((item) => (
                    <option key={item.id} value={item.id} onClick={() => setIsFormVisible(false)}>
                      {item.title}
                    </option>
                  ))
                ) : (
                  null
                )
              }
              <option onClick={handleAddNewEvent}>Add New Event</option>
            </Form.Select>
            <div className="error-message">{errorsInfo.members_invited}</div>
          </div>
          {isFormVisible && (
            <div className="mb-3">
              <FloatingLabel controlId="floatingDate" label="Event Name">
                <Form.Control
                  className="input-field form-control"
                  type="text"
                  placeholder="Enter event name"
                  name="name"
                  value={eventData.title}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </div>
          )}

          <div className="mb-3">
            <FloatingLabel controlId="floatingDatetime" label="Date">
              <Form.Control
                className="input-field form-control"
                type="date"
                name="date_time"
                value={eventData.date_time}
                placeholder="Select Start Date"
                onChange={handleChange}
                min={new Date().toISOString().split('T')[0]}
              />
            </FloatingLabel>
          </div>

          <div className="mb-3">
            <FloatingLabel controlId="floatingDate" label="Event Description">
              <Form.Control
                as="textarea"
                className="input-field form-control"
                placeholder="Enter event description"
                name="description"
                value={eventData.description}
                onChange={handleChange}
                style={{ height: '100px' }} // Adjust the height as needed
              />
            </FloatingLabel>
          </div>

          <div className="text-center mb-4">
            <Button className='w-100 btn-lg btn-primary shadow' disabled={loading} onClick={createUpcommingEvent}>
              {loading ? (
                <span className="indicator-progress" style={{ display: 'block' }}>
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">{params.id ? 'Update' : 'Create'}</span>
              )}
            </Button>
          </div>
          <div className="">
            <p onClick={() => navigate(appRoutes.upcomingEventsPageRoute)} className='text-center small text-primary text-decoration-underline pointer'>
              <i className="fa-solid fa-chevron-left small"></i> Back
            </p>
          </div>
        </form>

      </div>
    </div>
  );
};

export default CreateUpdate;
